










































export default {
  name: 'model-menu',
  methods: {
    onSelect(path: string) {
      this.$router.push({ path })
    },
  },
  computed: {
    model() {
      return this.$store.state.model
    },
    selected() {
      return this.$route.path.split('/')[1]
    },
  },
}
