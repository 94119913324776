

















import get from 'lodash/get'
import * as api from '@/services/api'
import { ModelBase } from '@/types'

export default {
  name: 'db-component',

  async created() {
    const res = await api.getPublicModels<ModelBase[]>()

    const myModelsRes = await api.get<ModelBase[]>('models')

    if (myModelsRes) this.$store.commit('updateDbModels', myModelsRes.data)
    this.dbData[1].children = res.data.map(this.model)
  },
  data() {
    return {
      publicModels: [],
      dbData: [
        {
          title: 'My models',
          expand: true,
          children: [],
        },
        {
          title: 'Public models',
          expand: false,
          children: [],
        },
      ],
    }
  },
  methods: {
    model(model: ModelBase) {
      return {
        title: model.name,
        type: 'model',
        model,
      }
    },
    onSelectChange(nodeArray) {
      if (get(nodeArray, '[0].type') !== 'model') return
      this.$store.commit('loadDbModel', nodeArray[0].model)
    },
  },
  computed: {
    dbModels() {
      return this.$store.state.dbModels
    },
  },
  watch: {
    dbModels(models: ModelBase[]) {
      this.dbData[0].children = models.map((model) => ({
        title: model.name,
        type: 'model',
        model,
      }))
    },
  },
}
