
























import './styles.scss'
import './server-events'
import Header from './components/header.vue'
import ModelMenu from './components/model-page/menu.vue'
import DbView from './components/model-page/db.vue'
import * as api from '@/services/api'

export default {
  name: 'app',
  components: {
    'header-component': Header,
    'model-menu': ModelMenu,
    'db-view': DbView,
  },
  data() {
    return {
      split: {
        vertical: 0.2,
        leftHorizontal: 0.5,
        rightHorizontal: 0.5,
      },
    }
  },
  async created() {
    await this.$store.dispatch('init')

    const { modelId } = this.$route.query

    if (modelId) {
      this.$router.replace({ query: {} })
      const modelRes = await api.get(`models/${modelId}`)
      if (modelRes) await this.$store.commit('loadDbModel', modelRes.data)
    }
  },
}
